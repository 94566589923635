/* Option 2: Import via CSS */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
a:hover {
    color: #0000ff; /* #0ab4ff */
    font-size: 1.1rem;
  }


  .sort-arrow {
    display: none;
}

th:hover .sort-arrow {
    display: inline-block;
    color: rgb(19, 31, 246);
}

.sortable-header {
  transition: color 0.3s ease;
}

.sortable-header:hover {
  color: #b25f06;
}




  .menu-anchor:hover {

    font-weight: bolder;
  }
  
  .menu-label {
    font-size: 13px;
  }
  
  button {
    right: 40px;
  }
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
    opacity: 0.15;
  }
  


.bb{
  border-bottom: 0.1px solid black;
}

.bt{
  border-top: 0.1px solid black;
}

.vheight{
  height: 60px;
  background-color: skyblue;
}

.fr{
  float: right;
}

body {
  font-family: 'Your-Heading-Font', sans-serif;
}
/* Option 2: Import via CSS */
.removestyle{
  text-decoration: none;
  color: black;
  font-size: 14px;
}

.removestyle:hover{
  text-decoration: none;
  color: black;
  background-color: white;
}

.imagesize{
  height: 20px;
  width: 20px;
}

.alertwidth{
  width: 460px !important;
}

.mt{
  margin-top: 120px;
}

.is{
  width: 650px;
  height:720px;
}

.fs{
  font-weight: bold;
  color: brown;
  font-size: 15px;
}

.buttonwidth{
  width: 460px;
}

.buttonwidth1{
  width: 200px;
}

.fs1{
  font-weight: bold;
  
  font-size: 10px;
}

.inputwidth{
  width: 460px !important;
}


.mardown{
  margin-bottom: 40px;
}



.padright{
  padding-right: 0 !important;
}



.col{
  float: 0 1 !important;
}

.dw{
  width: 100%;
  border-radius: 5px;
  height: 100;
}

.bodybackground{
  background-color: burlywood;
}

body
  {
    background-color: #e6ffff;
  }


/* for dash */

.profilewidth{
  font-size: 20px;

}


.ml{
  margin-left: 20px;
}

#fs{
  font-size: 23px;
  font-style: normal;
 
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}



.fontwt{
  font-weight: bold;
}



/*  For Userrights  */

.padleft{
  padding-left: 300px;
  padding-top: 20px;
}



.colsize{
  width: 20px;
}
.colsize1{
  width: 40px;
}


.readonly{
  color: gray;
}



/*  For userrights */
.dw2{
  width: 260px;
  border-radius: 5px;
}


.marfordrop{
    margin-right: 500px;
}

.searchCard{
  padding: 30px 40px 30px 40px;
}

.tablehead{
  background-color: skyblue;
}

.LabelHeader{
  font-weight: bold;
}


/* for airline*/
.custom-modal-size {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.custom-modal-size .modal-content {
  max-height: 500px;
}




/* Service Master */
.pad{
  padding: 0 !important;
  margin: 0 !important;
}

  .label
  {
    font-weight: bold;
    margin-left: 10px;
    font-size: large;
  }
  .radios[type="radio"]
  {
    border-color: #333;
  
  }
  .radios[type="checkbox"]
  {
    border-color: #333;
  width:100%;
  height: 2vw;
  }
  
  .width
  {
    width: 110px;
  }
  .paddingleft
  {
    padding-left:20px;
  }
  .btnwidth
  {
    width: 6vw;
  }
  .btnwidth2
  {
    width: 7vw;
  }
  .fullScreenCard {
   
    height: 80vh;
    background-color: white; /* Replace with the desired background color */
  }
  .error
  {
    color: red;
  }
  .read-only
  {
    background-color: red; /* Grey background color */
    /* You can also add additional styling properties like padding, border, etc. */
    color: grey;
  }
  #service
  {
    background-color: #f2f2f2;
  }


  /*Import tp*/
  #dw{
    /* margin-top: 8px; */
    width: 100%;
    height: 100%;
    border-radius: 5px;
  
  }


  .inputhead{
    font-weight: bold;
    font-size: 16px;
  }



  
  /*Export PCTM*/
  #dw1{
    margin-top: 8px;
    width: 250px;
    height: 35px;
    border-radius: 5px;
  
  }



  /* Paymeny & bills  */

  .tabspace{
    margin-right: 20px;
    border: 0.1px  solid  gray;
    border-bottom: none;
  }


  .backGround{
      background-color: rgb(181, 166, 166);
  }



  #dw2{
    margin-top: 8px;
    width: 500px;
    height: 35px;
    border-radius: 5px;
  
  }



  .inputSize{
    width: 200px !important;
  }


  /* Import PCTM*/
    #dw3{
    margin-top: 8px;
    width: 300px;
    height: 35px;
    border-radius: 5px;
  
  }

  .dw3{

    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    width: 200px;
    height: 35px;
   border:1px solid #dee2e6;
   border-radius: 0.375rem;
  
  }



  .marginfordrop{
    margin-right: 10px;
  }
  


  #dw4{
    margin-top: 8px;
    width: 160px;
    height: 35px;
    border-radius: 5px;
  
  }



  /*  Export Register */
#dw5{
  margin-top: 8px;
  width: 350px;
  height: 35px;
  border-radius: 5px;
}


/* GST Reports */
#dw6{
  margin-top: 8px;
  width: 280px;
  height: 35px;
  border-radius: 5px;
}






/* Service Master */
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css");
a:hover {
    color: #0000ff; /* #0ab4ff */
    font-size: 1.1rem;
  }
  .menu-anchor:hover {

    font-weight: bolder;
  }
  
  .menu-label {
    font-size: 13px;
  }
  
  button {
    right: 40px;
  }
  hr {
    margin-top: 0px;
    margin-bottom: 0px;
    opacity: 0.15;
  }
  


.bb{
  border-bottom: 0.1px solid black;
}

.bt{
  border-top: 0.1px solid black;
}

.vheight{
  height: 60px;
  background-color: skyblue;
}

.fr{
  float: right;
}


/* Option 2: Import via CSS */
.removestyle{
  text-decoration: none;
  color: black;
  font-size: 14px;
}

.removestyle:hover{
  text-decoration: none;
  color: black;
  background-color: white;
}

.imagesize{
  height: 20px;
  width: 20px;
}

.alertwidth{
  width: 460px !important;
}

.mt{
  margin-top: 120px;
}

.is{
  width: 650px;
  height:720px;
}

.fs{
  font-weight: bold;
  color: brown;
  font-size: 15px;
}

.buttonwidth{
  width: 460px;
}

.fs1{
  font-weight: bold;
  
  font-size: 10px;
}

.inputwidth{
  width: 460px !important;
}

.inputwidth1{
  width: 200px !important;
}


.mardown{
  margin-bottom: 40px;
}



.padright{
  padding-right: 0 !important;
}



.col{
  float: 0 1 !important;
}



.bodybackground{
  background-color: burlywood;
}




/* for dash */

.profilewidth{
  font-size: 20px;

}


.ml{
  margin-left: 20px;
}

#fs{
  font-size: 23px;
  font-style: normal;
 
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}



.fontwt{
  font-weight: bold;
}



/*  For Userrights  */

.padleft{
  padding-left: 300px;
  padding-top: 20px;
}



.colsize{
  width: 20px;
}
.colsize1{
  width: 40px;
}


.readonly{
  color: gray;
}



/*  For userrights */
.dw2{
  width: 260px;
  border-radius: 5px;
}


.marfordrop{
    margin-right: 500px;
}

.searchCard{
  padding: 30px 40px 30px 40px;
}

.tablehead{
  background-color: skyblue;
}

.LabelHeader{
  font-weight: bold;
}


/* for airline*/
.custom-modal-size {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
}

.custom-modal-size .modal-content {
  max-height: 500px;
}




/* Service Master */
.pad{
  padding: 0 !important;
  margin: 0 !important;
}

.label
{
  font-weight: bold;
  margin-left: 10px;
  font-size: large;
}
.radios[type="radio"]
{
  border-color: #333;
  
}
.custom-label.form-check-label
{
  color: black; /* Use the default color */
}
.custom-radio.form-check-input[disabled] {
  opacity: 1; /* This maintains the normal color even when disabled */
  cursor: not-allowed; /* Optional: Change cursor to indicate unclickable */
}
.radios[type="checkbox"]
{
  border-color: #333;
width:100%;
height: 2vw;
}

.width
{
  width: 110px;
}
.paddingleft
{
  padding-left:20px;
}
.btnwidth
{
  width: 6vw;
  transition: none;
}

.fullScreenCard {
 
  height: 80vh;
  background-color: white; /* Replace with the desired background color */
}
.error
{
  color: red;
}
.read-only
{
  background-color: red; /* Grey background color */
  /* You can also add additional styling properties like padding, border, etc. */
  color: grey;
}
#service
{
  background-color: #f2f2f2;

}
input[type="checkbox"]:disabled {
  /* Add your desired styles here to match the original color */
  opacity: 1; /* Adjust the opacity if needed */
  /* Add more styles to maintain the original color */
}
.table-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.table-column {
  flex: 1;
  padding: 8px;
}
.custom-table thead {
 
  height: 60px; /* Adjust the height as needed */
 
  
}

.custom-table thead th
{
  /* Define your custom styles for table header here */
  background-color: #87CEEB;
}

.bold label
{
  font-weight: 400;
}
.font
{
  font-size: 20px;
  font-weight: 400;
}
.containerservices label
{
  font-size: 15px;
  font-weight: 600;
}
.cardback
{
  background-color:rgb(226 232 240)
}
.labeldown label
{
  margin-top: 10px;
}
.singlebtn
{
  height: 3vw;
  width: 9vw;
  transition: none;
}
.matched-row {
  background-color: #f52b2b !important; /* Replace with your desired background color */
}
.error-input {
  border-color: #f52b2b;
}
.custom-datepicker {
  height: 45px;
  width: 100%;
  /* Add other styles as needed */
}
.form-input {
  height: 45px;
  width: 100%; /* Use full width */
}
.btn-disabled {
  /* Define styles to make the link look disabled */
  opacity: 0.5; /* You can adjust this value for desired visual effect */
  cursor: not-allowed;
  pointer-events: none;
}
.label-like-input {
  appearance: none;         /* Remove browser's default appearance */
}
.enhanceinput
{
  width: 10vw;
  height: 40px;
  border: 2px solid #ccc; 
  border-radius: 5px;       
  padding: 8px;            
  font-size: 16px;          
  color: #333;             
  outline: none;           
  transition: border-color 0.3s, box-shadow 0.3s; 
}


/* In your CSS file */

.sidebar-container {
  height: 72%;
  width: 100%;
  overflow-y: auto; /* Enable scrolling within the sidebar if needed */
  position: absolute;

  left: 0;
}



.css-1097eso{
  background-color: orange !important;
}









/*   Party Entry  */

.pageHead{
  font-size: 20px;
  font-weight: 600;
}


.forlabel{
  font-weight: 600;
  font-size: medium;
}


.inputField{
  font-size: 15px;
  background-color: gray;
  padding: 5px;
}


.allbutton{
  width: 105px;
}



/*  pdf */
/* Style.css */
.custom-header {
  background-color: white !important;  
  color: black !important;
}


.buttton:hover{
  color: white !important;
}


.MenuColor:hover {
  color: black;
}

.menu-item.hovered {
  color: black; /* Change color on hover */
}

/* Define a class for the hover effect */
.menu-item {
  height: 40px;
  color: white;
  transition: color 0.3s; /* Add a smooth color transition */
}

/* Define the hover effect */
.menu-item:hover {
  color: black;
}


.css-dip3t8 {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 3;
    width: 100% !important;
    background-color: rgb(0 0 0 / 70%) !important;
}

/* .css-1wvake5 */


.bgcolo {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 3;
  background-color: rgb(0 0 0 / 70%) !important;
}


.font1
{
  font-family: 'Your-Data-Font'!important;
}

.css-dip3t8 {
  position: relative;
  height: 100% !important;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100% !important;
  z-index: 3;
  background-color: rgb(249, 249, 249, 0.7);
}
.dropstart .dropdown-toggle::before
{
  color: #fff !important;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #000; /* Adjust the color as needed */
}

.close-button:hover {
  color: red; /* Change the color on hover if desired */
}
.error-message
{
 color: red;
}
.redText {
  color: red;
  font-weight: bolder;
  cursor:default;
}
.orangeText
  {
    color: orange;
    font-weight: bolder;
    cursor:default;
  }

  /* Tab Styles */


.nav-tabs .nav-link.active
{
  background-color: hsla(177, 51%, 93%, 0.894) !important;
 
}
.nav-tabs .nav-link.active h6 {
  font-weight: bold;
  color: black; /* Set the font color to black */
}

.total-row td {
  font-weight: bold !important;
  background-color: #fff !important;
  border: 1px solid black !important;
  height: 50px;
}

.highlight-red {
  background-color: rgb(246, 198, 198) !important;
}

.refresh-button {
  position: absolute;
  top: 10px;
  right: 100px;
  background: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}
.rounded-image {
  border-radius: 50%;
  width: 200px; /* Example width */
  height: 200px; /* Example height */
  object-fit: cover; /* Ensures the image covers the entire container */
}

.centered-cell {
  text-align: center; /* Horizontally center */
  vertical-align: middle; /* Vertically center */
  padding: 0; /* Optional: Adjust padding as needed */
}

.small-input {
  height: 35px;    /* Adjust the height */
  padding: 5px;    /* Adjust padding to control spacing inside */
  font-size: 14px; /* Adjust font size for text */
}


.error-messageNew {
  position: absolute;
  bottom: -20px;  
  left: 0;
  color: #f52b2b;
  font-size: 12px;  
}


.error-border {
  border: 1px solid #f52b2b !important;
}