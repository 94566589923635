body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    
}

body .react-datepicker-wrapper.custom-react-datepicker-wrapper {
  /* Your custom styles here */
  display: block;
  /* Add any other styles you want to override */
}
